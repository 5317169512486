import { FC } from 'react';
import { Backdrop as MBackDrop, CircularProgress } from '@mui/material';

export const Backdrop: FC<{ loading?: boolean }> = ({ children, loading }) => {
    return (
        <>
            {loading && (
                <MBackDrop open sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                    <CircularProgress color="inherit" />
                </MBackDrop>
            )}
            {children}
        </>
    );
};
