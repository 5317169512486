import { FC, useEffect, useState, createContext, useContext, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Emitter } from 'mitt';

import { PayLaterCheckout } from './PayLaterCheckout';
import { getTheme } from '../theme';
import { Events } from './emitter';

import { CheckoutButtonProps } from './CheckoutButton';
import { postCheckout, frontendUrl } from './api';

import { checkoutBtnClickEvent } from '../analytics';

type DataContextType = {
    checkoutData: Events['checkout'];
    paylaterData: Events['paylater'];
    loading: boolean;
    open: boolean;
    redirect: string;
    handleClickOpen: () => void;
    handleClose: () => void;
    getCheckoutTotal: () => string | number | undefined;
    handleClickTutorial: () => void;
};

const AppDataContext = createContext<DataContextType | null>(null);

export const useAppDataContext = () => {
    let context = useContext(AppDataContext);
    if (context === null) {
        throw Error('AppDataContext undefined');
    }
    return context;
};

export const App: FC<{
    emitter: Emitter<Events>;
    btnProps?: CheckoutButtonProps;
    showAsLowAs?: boolean;
}> = ({ emitter, btnProps, showAsLowAs }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState<string>('');

    const [checkoutData, setCheckoutData] = useState<Events['checkout']>({
        store_form: {
            owner_name: '',
            owner_email: '',
            owner_phone_number: '',
            store_number: '',
        },
    });
    const [paylaterData, setPaylaterData] = useState<Events['paylater']>({
        total: undefined,
        partner_name: '',
        partner_invoice_uuid: '',
    });

    const loadCheckout = async () => {
        try {
            const data = await postCheckout(checkoutData);
            setRedirect(data);
            // setRedirect('http://localhost:5001/?' + data.split('?')[1]);
            // setRedirect('https://checkout.app.golendica.com/?' + data.split('?')[1]);
        } catch (e) {
            console.log(e);
            setRedirect(`${frontendUrl}/home`);
            setLoading(false);
        }
    };

    const loadPayLater = () => {
        try {
            setRedirect(
                `${frontendUrl}?partner_name=${paylaterData!.partner_name}&partner_invoice_uuid=${
                    paylaterData!.partner_invoice_uuid
                }`
                // `http://localhost:5001/?partner_name=${
                //     paylaterData!.partner_name
                // }&partner_invoice_uuid=${paylaterData!.partner_invoice_uuid}`
            );
            // setRedirect(
            //     `http://localhost:5001/?partner_name=${paylaterData!.partner_name}&partner_invoice_uuid=${paylaterData!.partner_invoice_uuid}`
            // );
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const openOverlay = () => {
        setTimeout(() => {
            setOpen(true);
            setLoading(false);
        }, 500);
    };

    const getCheckoutTotal = () => {
        if (!!checkoutData) {
            if (!!checkoutData!.totalPrice) {
                return checkoutData!.totalPrice;
            } else if (!!checkoutData!.checkout && !!checkoutData!.checkout!.totalPrice) {
                return checkoutData!.checkout!.totalPrice;
            }
        }
        return undefined;
    };

    const handleClickOpen = async () => {
        setLoading(true);
        if (!!getCheckoutTotal()) {
            checkoutBtnClickEvent({ type: 'checkout', total: checkoutData!.totalPrice });
            loadCheckout();
        } else if (!!paylaterData!.total) {
            checkoutBtnClickEvent({ type: 'paylater', ...paylaterData });
            loadPayLater();
        } else {
            setRedirect(`${frontendUrl}/error`);
        }
        openOverlay();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickTutorial = () => {
        setLoading(true);
        setRedirect('https://www.youtube.com/embed/oPZ1V5mmnFQ');
        openOverlay();
    };

    useEffect(() => {
        async function onCheckout(e: Events['checkout']) {
            if (!!e.sparklayer_cart) {
                setCheckoutData(e);
                return;
            }
            if (!!e!.totalPrice) {
                setCheckoutData((currentValue: any) => ({ ...currentValue, checkout: e }));
                return;
            }
            setCheckoutData((currentValue: any) => ({ ...currentValue, ...e }));
        }
        async function onPaylater(e: Events['paylater']) {
            setPaylaterData(currentValue => ({ ...currentValue, ...e }));
        }

        emitter.on('checkout', onCheckout);
        emitter.on('paylater', onPaylater);

        return () => {
            emitter.off('checkout', onCheckout);
            emitter.off('paylater', onPaylater);
        };
    }, [emitter]);

    useEffect(() => {
        const form = document.getElementById(
            'mauticform_7020plumposcicisinstallsubmissionform'
        ) as HTMLFormElement;
        const getInputValue = (el: HTMLInputElement) => {
            if (!!el && !!el.value) {
                return el.value;
            }
            return null;
        };
        if (!!form) {
            const passStoreForm = () => {
                setCheckoutData((currentValue: any) => ({
                    ...currentValue,
                    store_form: {
                        owner_name:
                            getInputValue(form!.elements['mauticform[name_first__last]']) ||
                            currentValue!.store_form!.owner_name ||
                            '',
                        owner_email:
                            getInputValue(form!.elements['mauticform[email]']) ||
                            currentValue!.store_form!.owner_email ||
                            '',
                        owner_phone_number:
                            getInputValue(form!.elements['mauticform[store_number]']) ||
                            currentValue!.store_form!.owner_phone_number ||
                            '',
                        store_number:
                            getInputValue(form!.elements['mauticform[general_manager]']) ||
                            currentValue!.store_form!.store_number ||
                            '',
                    },
                }));
            };
            form!.addEventListener('change', passStoreForm, false);
            return () => {
                form!.removeEventListener('change', passStoreForm, false);
            };
        }
    }, []);

    const theme = useMemo(getTheme, []);

    return (
        <ThemeProvider theme={theme}>
            <AppDataContext.Provider
                value={{
                    checkoutData,
                    paylaterData,
                    open,
                    loading,
                    redirect,
                    handleClickOpen,
                    handleClose,
                    getCheckoutTotal,
                    handleClickTutorial,
                }}
            >
                <PayLaterCheckout btnProps={btnProps} showAsLowAs={showAsLowAs} />
            </AppDataContext.Provider>
        </ThemeProvider>
    );
};
