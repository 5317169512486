import { render, unmountComponentAtNode } from 'react-dom';
import { StyledEngineProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { App } from './Checkout/App';
import { emitter, Events } from './emitter';
import { FC, useEffect } from 'react';

import {
    initAnalytics,
    lendicaInitEvent,
    payLaterRenderEvent,
    payLaterUpdateEvent,
} from './analytics';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://ecfacb81816b42009db7ab00e396a27c@o4504164196352000.ingest.sentry.io/4504334135787520',
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    environment: process.env.NODE_ENV,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
});

let isAnalyticsInitialized = false;

const roots: HTMLElement[] = [];

const OnRender: FC<{
    callback: () => void;
}> = ({ callback }) => {
    useEffect(callback, [callback]);

    return null;
};

type btnVariant = 'text' | 'outlined' | 'contained';
type btnColor = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

interface paylaterBtnProps {
    container?: string | undefined;
    height?: number | undefined;
    variant?: btnVariant | undefined;
    color?: btnColor | undefined;
}

function renderPayLaterButton({
    container = 'lendica-paylater-container',
    height = 37,
    variant = 'contained',
    color,
}: paylaterBtnProps) {
    return new Promise((res, rej) => {
        let renderCounter = 0;
        const containers = [
            document.getElementById(container),
            ...document.getElementsByClassName(container),
        ].filter(container => container !== null) as Element[];
        if (containers.length === 0) {
            rej('lendica-paylater-container elements are not found on the page');
        }

        if (roots.length > 0) {
            rej('lendica-paylater-container elements are already initialized');
            return;
        }

        class PayLaterComponent extends HTMLElement {
            mountPoint!: HTMLElement;

            connectedCallback() {
                const shadowRoot = this.attachShadow({ mode: 'open' });
                const mountPoint = document.createElement('div');
                this.mountPoint = mountPoint;
                shadowRoot.appendChild(mountPoint);

                const cache = createCache({
                    key: 'my-prefix-key',
                    prepend: true,
                    container: shadowRoot,
                });

                render(
                    <StyledEngineProvider injectFirst>
                        <CacheProvider value={cache}>
                            <App emitter={emitter} btnProps={{ height, variant, color }} />
                            <OnRender
                                callback={() => {
                                    renderCounter++;
                                    if (renderCounter == containers.length) {
                                        res(null);
                                    }
                                }}
                            />
                        </CacheProvider>
                    </StyledEngineProvider>,
                    mountPoint
                );
            }

            disconnectedCallback() {
                unmountComponentAtNode(this.mountPoint);
            }
        }

        if (!customElements.get('paylater-component')) {
            customElements.define('paylater-component', PayLaterComponent);
        }

        containers.forEach(container => {
            const root = document.createElement('paylater-component');
            roots.push(root);
            container.appendChild(root);
        });
    });
}

function renderButton(props?: paylaterBtnProps | undefined) {
    return new Promise(async res => {
        if (!isAnalyticsInitialized) {
            initAnalytics();
            isAnalyticsInitialized = true;
        }
        lendicaInitEvent({ partner_company_uuid: location, partner_name: location.hostname });
        payLaterRenderEvent();
        if (document.readyState === 'complete') {
            await renderPayLaterButton(props ? props : {});
            res(null);
            return;
        }
        window.addEventListener('load', async () => {
            await renderPayLaterButton(props ? props : {});
            res(null);
        });
    });
}

export function paylater(data: Events['paylater']) {
    payLaterUpdateEvent(data);
    emitter.emit('paylater', data);
}

// function openPaylater(data: Events['paylater']) {
//     emitter.emit('open', data);
// }

paylater.renderButton = renderButton;
// paylater.open = openPaylater;

export function checkout(data: Events['checkout']) {
    payLaterUpdateEvent(data);
    emitter.emit('checkout', { total: data!.totalPrice, ...data });
}

export function destroy() {
    if (roots.length === 0) return;
    while (roots.length > 0) {
        const root = roots.pop()!;
        root.parentElement?.removeChild(root);
    }
}
