import { ReactElement } from 'react';
import { Box, Tooltip as MuiTooltip, Typography, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IntroImage } from './assets';

export interface CustomTooltipProps {
    handleLearnMoreClick: () => void;
    handleClose: () => void;
    children: ReactElement<any, any>;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
        filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))',
        fontSize: 11,
        maxWidth: 400,
        padding: 0,
    },
    [`& .${tooltipClasses.arrow}`]: {
        'color': '#fff',
        '&:before': {
            border: '1px solid #FCFCFD',
        },
    },
}));

export const Tooltip = ({ handleLearnMoreClick, handleClose, children }: CustomTooltipProps) => {
    return (
        <LightTooltip
            arrow
            placement="top"
            leaveDelay={500}
            TransitionComponent={Zoom}
            PopperProps={{ style: { zIndex: 990001 } }}
            TransitionProps={{ timeout: 500 }}
            onClose={handleClose}
            title={
                <Box>
                    <Box sx={{ px: 2, py: 1 }}>
                        <Typography
                            variant="subtitle1"
                            fontSize={theme => theme.typography.pxToRem(16)}
                            fontWeight={400}
                        >
                            Introducing Lendica PayLater
                        </Typography>
                        <Typography
                            variant="body1"
                            fontSize={theme => theme.typography.pxToRem(12)}
                            sx={{ color: '#4A4A68' }}
                        >
                            Buy now and pay over time.
                            <span
                                style={{
                                    textDecoration: 'underline',
                                    paddingLeft: 2,
                                    cursor: 'pointer',
                                }}
                                onClick={handleLearnMoreClick}
                            >
                                Learn more
                            </span>
                            .
                        </Typography>
                    </Box>
                    <IntroImage />
                </Box>
            }
        >
            {children}
        </LightTooltip>
    );
};
