declare global {
    interface Window {
        dataLayer: any[];
    }
}

export function initAnalytics() {
    appendGTagToDocument();
    initGTag();
}

function appendGTagToDocument() {
    const script = document.createElement("script");

    script.src = "https://www.googletagmanager.com/gtag/js?id=G-DRKXGBK0D0";
    script.async = true;

    document.body.appendChild(script);
}

function initGTag() {
    window.dataLayer = window.dataLayer || [];

    window.gtag = function() {
        // @ts-ignore
        dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', 'G-DRKXGBK0D0', { transport_url: 'https://gtm-pod.ue.r.appspot.com' });
}

export function lendicaInitEvent({ partner_company_uuid, partner_name }: any = {}) {
    if (partner_company_uuid) {
        gtag('event', 'lendica_init', {
            partner_name: partner_name || location.hostname,
            company_uuid: partner_company_uuid,
        });
    } else {
        gtag('event', 'lendica_unauthorized_init', {
            partner_name: location.hostname,
        });
    }
}

export function payLaterRenderEvent() {
    gtag('event', 'paylater_render', {
        partner_name: location.hostname,
    });
}

export function payLaterUpdateEvent({ total, partner_invoice_uuid, partner_company_uuid, partner_name }: any = {}) {
    gtag('event', 'paylater_update', {
        partner_name: partner_name || location.hostname,
        company_uuid: partner_company_uuid || 'NA',
        invoice_uuid: partner_invoice_uuid || 'NA',
        invoice_amount: total
    });
}

export function checkoutUpdateEvent({ totalPrice, partner_name }: any = {}) {
    gtag('event', 'checkout_update', {
        partner_name: partner_name || location.hostname,
        invoice_amount: totalPrice
    });
}

export function checkoutBtnClickEvent({ type, total, partner_invoice_uuid, partner_company_uuid, partner_name }: any = {}) {
    gtag('event', 'checkout_click', {
        type: type,
        partner_name: partner_name || location.hostname,
        invoice_amount: total,
        company_uuid: partner_company_uuid || 'NA',
        invoice_uuid: partner_invoice_uuid || 'NA',
    });
}

export function learnMoreEvent({ partner_name }: any = {}) {
    gtag('event', 'learn_more', {
        partner_name: partner_name || location.hostname,
    });
}