import { FC, useState, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import { Box, Button, Grow, useMediaQuery, IconButton } from '@mui/material';
import { ShoppingCartCheckout, PlayCircleOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
import root from 'react-shadow/emotion';

import { CheckoutButton, CheckoutButtonProps } from './CheckoutButton';

import { useAppDataContext } from './App';
import { frontendUrl } from './api';
import { LearnMore, OffersPreview, Tooltip } from './components';

import { learnMoreEvent } from '../analytics';

interface PayLaterCheckoutProps {
    btnProps?: CheckoutButtonProps;
    onClick?: () => void;
    showAsLowAs?: boolean;
}

interface OverlayProps {
    open: boolean;
    src: string | URL;
    handleClose?: () => void;
}

interface ModalProps {
    open?: boolean;
}

const Modal = styled('div', { shouldForwardProp: prop => prop !== 'open' })<ModalProps>(props => ({
    boxSizing: 'border-box',
    display: props!.open! ? 'block' : 'none',
    position: 'fixed',
    zIndex: 992039,
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.4)',
}));

const ModalContent = styled('div')({
    backgroundColor: '#f8f8fb',
    margin: '0 auto',
    padding: 0,
    border: 'none',
    width: '100%',
    height: '100%',
    position: 'fixed',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    whiteSpace: 'nowrap',
    maxHeight: '100vh',
    zIndex: 992040,
});

const Frame = styled('iframe')({
    border: 'none',
    width: '100%',
    height: '100%',
    pb: 2,
    zIndex: 992041,
});

const Overlay: FC<OverlayProps> = ({ open, src, handleClose }) => {
    const matches = useMediaQuery('(min-width:600px)');

    return ReactDOM.createPortal(
        <root.div>
            <Modal open={open}>
                <Grow in={open} {...(open ? { timeout: 300 } : {})}>
                    <ModalContent>
                        <Box sx={{ height: '100%' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    height: '100%',
                                }}
                                data-testid="paylater-container"
                            >
                                <Frame src={`${src}`} data-testid="paylater-overlay"></Frame>
                            </Box>
                            {matches ? (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 16,
                                        right: 24,
                                        zIndex: 992042,
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        startIcon={<ShoppingCartCheckout />}
                                        onClick={handleClose}
                                    >
                                        Back to cart
                                    </Button>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 72,
                                        left: 0,
                                        zIndex: 992042,
                                        bgcolor: '#4a4a68',
                                        borderRadius: '0 4px 4px 0',
                                        p: '2px',
                                        // '&:hover': {
                                        //     p: '3px 3px 3px 12px',
                                        // },
                                    }}
                                >
                                    <IconButton size="small" color="warning" onClick={handleClose}>
                                        <ShoppingCartCheckout />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </ModalContent>
                </Grow>
            </Modal>
        </root.div>,
        document.body
    );
};

export const PayLaterCheckout: FC<PayLaterCheckoutProps> = ({ btnProps, showAsLowAs }) => {
    const [payLaterHovered, setPayLaterHovered] = useState(false);
    const [openOffersPreview, setOpenOffersPreview] = useState(false);
    const {
        // checkoutData,
        paylaterData,
        open,
        loading,
        redirect,
        handleClickOpen,
        handleClose,
        getCheckoutTotal,
        handleClickTutorial,
    } = useAppDataContext();

    const handleLearnMoreClick = () => {
        learnMoreEvent({ partner_name: paylaterData?.partner_name });
        setOpenOffersPreview(true);
    };

    const getTerm = (subtotal: number | string | undefined) => {
        if (!subtotal) {
            return 0.0;
        }
        return ((+subtotal * 1.0775 * (1 + 0.05 * (20 / 52))) / 20).toFixed(0);
    };

    return (
        <div>
            <Tooltip
                handleLearnMoreClick={handleLearnMoreClick}
                handleClose={() => setPayLaterHovered(false)}
            >
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    onMouseOver={() => setPayLaterHovered(true)}
                >
                    <CheckoutButton
                        hovered={payLaterHovered}
                        fullWidth={true}
                        variant="contained"
                        onClick={handleClickOpen}
                        loading={loading}
                        disabled={!getCheckoutTotal() && !paylaterData!.total}
                        {...btnProps}
                    >
                        {btnProps?.children}
                    </CheckoutButton>
                    <LearnMore handleLearnMoreClick={handleLearnMoreClick} />
                </Box>
            </Tooltip>

            <Overlay open={open} src={`${redirect}`} handleClose={handleClose}></Overlay>
            <OffersPreview
                open={openOffersPreview}
                handleClose={() => setOpenOffersPreview(false)}
            />
        </div>
    );
};
