import mitt from 'mitt';

interface PaylaterProps {
        total: number | string | undefined,
        partner_name?: string,
        partner_invoice_uuid?: string | number,
}

// interface OpenPayLaterProps extends PaylaterProps {
//         onOpen?: () => void | undefined,
//         onFail?: () => void | undefined,
//         onSuccess?: () => void | undefined,
// }

export type Events = {
    checkout: any,
    paylater: PaylaterProps,
//     open?: OpenPayLaterProps,
};

export const emitter = mitt<Events>();
