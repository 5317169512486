import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Link,
    Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    TimerOutlined,
    MonetizationOn,
    AddModeratorRounded,
    AirplaneTicketRounded,
    Share,
    Email,
    LinkedIn,
    Twitter,
} from '@mui/icons-material';
import { PayLaterHeader } from '@lendica/components';
import { HowItWorksImage, TalkToImage, TrySampleImage } from './assets';
import root from 'react-shadow/emotion';
import ReactDOM from 'react-dom';

export interface OffersPreviewProps {
    open: boolean;
    handleClose: () => void;
}

const benefits = [
    {
        icon: TimerOutlined,
        title: 'Instant Decision',
        subTitle: 'Apply in minutes and get instant decision.',
    },
    {
        icon: MonetizationOn,
        title: 'Fair Pricing',
        subTitle: 'Get one of the lowest rates and fees.',
        titleWidth: '50%',
    },
    {
        icon: AddModeratorRounded,
        title: 'No Credit Impact',
        subTitle: 'No impact on your credit even if you’re rejected.',
    },
    {
        icon: AirplaneTicketRounded,
        title: 'More Buying Power',
        subTitle: 'Boost your credit limit as your sales grow.',
    },
];

const actions = [
    {
        image: HowItWorksImage,
        description: 'Watch how it works',
        link: 'https://youtu.be/oPZ1V5mmnFQ',
    },
    {
        image: TrySampleImage,
        description: 'Try sample offer calculator',
        link: 'https://paylater.app.golendica.com/offer-calculator',
    },
    {
        image: TalkToImage,
        description: 'Talk to a representative',
        link: 'https://calendly.com/chase-meetinglendica/',
    },
];

const exploreLinks = [
    {
        text: 'Explore A/P financing - PayLater →',
        link: 'https://docsend.com/view/cvbsxpdqhzg94pig',
    },
    {
        text: 'Explore A/R financing - FundNow →',
        link: 'https://docsend.com/view/tr6ccrq9sz8i3age',
    },
    {
        text: 'Explore working capital - DrawDown →',
        link: 'https://docsend.com/view/3n552jrzpbcssi2c',
    },
];

const socialMedia = [
    {
        icon: Share,
        link: 'mailto:?subject=PayLater by Lendica - Fast and affordable business finance&body=Lendica provides fast and affordable finance. Try "PayLater" offer calculator: https://paylater.app.golendica.com/offer-calculator',
    },
    {
        icon: Email,
        link: 'mailto:info@golendica.com',
    },
    {
        icon: LinkedIn,
        link: 'https://www.linkedin.com/company/lendica/',
    },
    {
        icon: Twitter,
        link: 'https://twitter.com/GoLendica',
    },
];

export const OffersPreview = ({ open, handleClose }: OffersPreviewProps) => {
    return ReactDOM.createPortal(
        <root.div>
            <Dialog open={open} onClose={handleClose} sx={{ zIndex: 992030 }}>
                <DialogTitle sx={{ m: 0, p: 2.5 }}>
                    {handleClose ? (
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                            }}
                        >
                            <CancelIcon />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent
                    sx={{
                        width: 400,
                        height: '82vh',
                        padding: '0 36px',
                        boxSizing: 'border-box',
                        backgroundColor: '#FCFCFD',
                    }}
                >
                    <Box display="flex" justifyContent="center" flexDirection="column">
                        <Box alignSelf="center">
                            <PayLaterHeader height={20} />
                        </Box>

                        <Typography
                            variant="h6"
                            sx={{ textAlign: 'center', mt: 3 }}
                            fontWeight={700}
                            fontSize={theme => theme.typography.pxToRem(23)}
                        >
                            Order now and pay over time.
                        </Typography>

                        <Typography
                            sx={{ textAlign: 'center' }}
                            fontWeight={400}
                            fontSize={theme => theme.typography.pxToRem(13)}
                        >
                            Fast and affordable payment terms now available.
                            <br />
                            Select PayLater at checkout.
                        </Typography>

                        <Box
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="space-around"
                            sx={{ color: '#2E3192' }}
                        >
                            {benefits.map(({ titleWidth, icon: Icon, ...benefit }, index) => (
                                <Typography
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{ width: '38%' }}
                                >
                                    <Box sx={{ pt: 4 }}>
                                        <Icon />
                                    </Box>
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: 800,
                                            fontSize: 17,
                                            py: 1,
                                            width: titleWidth ? titleWidth : '100%',
                                        }}
                                    >
                                        {benefit.title}
                                    </Box>
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: 500,
                                            color: '#4A4A68',
                                            fontSize: 10,
                                        }}
                                    >
                                        {benefit.subTitle}
                                    </Box>
                                </Typography>
                            ))}
                        </Box>

                        <Typography
                            fontWeight={500}
                            fontSize={theme => theme.typography.pxToRem(14)}
                            sx={{ pt: 7, pb: 1.5 }}
                        >
                            Quick actions
                        </Typography>

                        {actions.map(({ image: Image, ...action }, index) => (
                            <Link
                                key={index}
                                href={action.link}
                                target="_blank"
                                underline="none"
                                sx={{
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'border': '0.5px solid #BABAC7',
                                    'backgroundColor': '#FFFFFF',
                                    'borderRadius': '6px',
                                    'my': 0.5,
                                    'p': 0.75,
                                    '&:hover': {
                                        backgroundColor: '#F5F5F5',
                                    },
                                }}
                            >
                                <Image />
                                <Typography
                                    fontWeight={500}
                                    fontSize={theme => theme.typography.pxToRem(14)}
                                    sx={{ pl: 1.5 }}
                                >
                                    {action.description}
                                </Typography>
                            </Link>
                        ))}

                        <Typography
                            fontWeight={500}
                            fontSize={theme => theme.typography.pxToRem(14)}
                            sx={{ mt: 5, mb: 1 }}
                        >
                            Explore Lendica
                        </Typography>

                        {exploreLinks.map(({ link, text }, index) => (
                            <Link
                                key={index}
                                href={link}
                                target="_blank"
                                underline="hover"
                                sx={{ color: '#0E5AA1' }}
                            >
                                <Typography
                                    fontWeight={400}
                                    fontSize={theme => theme.typography.pxToRem(14)}
                                >
                                    {text}
                                </Typography>
                            </Link>
                        ))}

                        <Box sx={{ color: '#8C8CA1', mt: 3 }}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ mt: 2 }}
                            >
                                {socialMedia.map(({ icon: Icon, ...media }, index) => (
                                    <Link
                                        key={index}
                                        href={media.link}
                                        target="_blank"
                                        sx={{ color: '#8C8CA1' }}
                                    >
                                        <Icon
                                            key={index}
                                            sx={{
                                                'fontSize': 18,
                                                'px': 0.5,
                                                '&:hover': { color: '#c2c2c2' },
                                            }}
                                        />
                                    </Link>
                                ))}

                                <Typography
                                    variant="caption"
                                    fontSize={theme => theme.typography.pxToRem(11)}
                                    sx={{ pl: 0.5, color: '#8C8CA1' }}
                                >
                                    <Link
                                        underline="hover"
                                        href="https://golendica.com/terms"
                                        target="_blank"
                                        sx={{ color: '#8C8CA1' }}
                                    >
                                        Terms of Use
                                    </Link>
                                    &nbsp;|&nbsp;
                                    <Link
                                        underline="hover"
                                        href="https://golendica.com/privacy"
                                        target="_blank"
                                        sx={{ color: '#8C8CA1' }}
                                    >
                                        Privacy Policy
                                    </Link>
                                </Typography>
                            </Box>

                            <Typography
                                fontSize={theme => theme.typography.pxToRem(11)}
                                sx={{
                                    mt: 1,
                                    mb: 4,
                                    textAlign: 'center',
                                }}
                            >
                                Copyright © 2023 Lendica Corp. All rights reserved.
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </root.div>,
        document.body
    );
};
