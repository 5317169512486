import { Box, Typography } from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

export interface LearnMoreProps {
    handleLearnMoreClick: () => void;
}

export const LearnMore = ({ handleLearnMoreClick }: LearnMoreProps) => {
    return (
        <Typography
            sx={{ pt: 1, color: '#0E0E2C' }}
            variant="body1"
            fontSize={theme => theme.typography.pxToRem(12)}
            fontWeight={400}
            paragraph
        >
            Click to preview your offers to pay over time.
            <br />
            <Box display="flex" alignItems="center">
                No sign up required.
                <span
                    style={{ textDecoration: 'underline', paddingLeft: 2, cursor: 'pointer' }}
                    onClick={handleLearnMoreClick}
                >
                    Learn more
                </span>
                .
                <ErrorOutlineIcon
                    sx={{ pl: 0.25, color: '#3E3E56', fontSize: '16px', cursor: 'pointer' }}
                    onClick={handleLearnMoreClick}
                />
            </Box>
        </Typography>
    );
};
