import axios from 'axios';

// For lendica.js CDN script deployment, manually set baseUrl for dev and prod,
// and deploy to static-idev.golendica and static.golendica separately
const getBackendURL = (env: string | undefined) => {
    switch (env) {
        case 'idev':
            return 'https://api-dev.golendica.com/api/v1';
        case 'sandbox':
            return 'https://api-sandbox.golendica.com/api/v1';
        case 'prod':
            return 'https://api.golendica.com/api/v1';
        default:
            return 'https://api.golendica.com/api/v1';
    }
};

const getFrontendURL = (env: string | undefined) => {
    switch (env) {
        case 'idev':
            return 'https://paylater.app-idev.golendica.com';
        case 'sandbox':
            return 'https://paylater.app-sandbox.golendica.com';
        case 'prod':
            return 'https://paylater.app.golendica.com';
        default:
            return 'https://paylater.app.golendica.com';
    }
};

export const env = 'prod';

export const frontendUrl = getFrontendURL(env);

export const axiosInstance = axios.create({ baseURL: getBackendURL(env) });

export const postCheckout = async (checkout: any) => {
    return (await axiosInstance.post('/paylater/shopify/checkout', checkout)).data;
};
