export const IntroImage = () => (
    // <img
    //     width={368}
    //     height={164}
    //     src="https://lendica-public.s3.amazonaws.com/4steps_test_2.gif"
    // ></img>
    <video
        width="368"
        height="164"
        src="https://lendica-public.s3.amazonaws.com/checkout4steps.mp4"
        autoPlay
        loop
        muted
    ></video>
);
